import { useState, useEffect } from "react"

export function useFetch<T>(url, dependencies = []) {
    const [data, setData] = useState<T[]>([])
    const [isLoading, setIsLoading] = useState(false)
    const [error, setError] = useState(null)

    useEffect(() => {
        async function fetchData() {
            setIsLoading(true)
            setError(null)
            try {
                const response = await fetch(url)
                const result = await response.json()
                setData(result)
            } catch (err) {
                setError(err)
            } finally {
                setIsLoading(false)
            }
        }

        fetchData()
    }, dependencies)

    return { data, isLoading, error }
}
